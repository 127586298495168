import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby-plugin-intl";
import Img from "gatsby-image";


class ServicesComp extends React.Component {

  render(){
    return (
      <StaticQuery
        query={graphql`
          query {
            backgroundServices: file(relativePath: { eq: "TEIDE.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            serviceIcon1: file(relativePath: { eq: "services/step-by-step.png" }) {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            serviceIcon2: file(relativePath: { eq: "services/property-tax.png" }) {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            serviceIcon3: file(relativePath: { eq: "services/reforms.png" }) {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <BackgroundImage Tag="section"
                fluid={data.backgroundServices.childImageSharp.fluid}
                className="services-section spad"
                id="our-services"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 pl-lg-0">
                      <div className="row">
                        <div className="section-title">
                          {this.props.page !== "service" &&
                            <h3>{this.props.intl.formatMessage({ id:'Services'})}</h3>
                          }
                          {this.props.page === "service" &&
                            <div className="serviceMainTitle">
                              <h2>{this.props.intl.formatMessage({ id:'Services'})}</h2>
                            </div>
                          }
                          <p>{this.props.intl.formatMessage({ id:'Services-subtitle'})}</p>
                        </div>
                      </div>
                      <div className="row services">
                        <div className="col-lg-4 service-item">
                          <Link to={"/service-buying-a-property"} >
                            <div className="row">
                              <Img className="serviceItemImg" fluid={data.serviceIcon1.childImageSharp.fluid}></Img>
                            </div>
                            <div className="row service-text text-green">
                              <h5>{this.props.intl.formatMessage({ id:'Service1Title'})}</h5>
                              <p>{this.props.intl.formatMessage({ id:'Service1Content'})}</p>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 service-item">
                          <Link to={"/service-tax-administration"} >
                            <div className="row">
                              <Img className="serviceItemImg" fluid={data.serviceIcon2.childImageSharp.fluid}></Img>
                            </div>
                            <div className="row service-text text-green">
                              <h5>{this.props.intl.formatMessage({ id:'Service2Title'})}</h5>
                              <p>{this.props.intl.formatMessage({ id:'Service2Content'})}</p>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4 service-item">
                          <Link to={"/service-renovation-your-property"} >
                            <div className="row">
                              <Img className="serviceItemImg" fluid={data.serviceIcon3.childImageSharp.fluid}></Img>
                            </div>
                            <div className="row service-text text-green">
                              <h5>{this.props.intl.formatMessage({ id:'Service3Title'})}</h5>
                              <p>{this.props.intl.formatMessage({ id:'Service3Content'})}</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BackgroundImage>
        )}
      />
    )}
}


export default ServicesComp
