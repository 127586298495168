import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl} from "gatsby-plugin-intl";
import ServicesComp from '../components/servicesComp';
import { graphql } from "gatsby"

const Services = ({ data,intl }) => (

    <Layout>
    <SEO title={intl.formatMessage({ id: 'Menu-services' })} />
    <section 
        style={{
            background: `#14312f1c`
        }}
    >
        {/**<BackgroundImage Tag="section"
            fluid={data.placeholderImage.childImageSharp.fluid}
            className="page-top-section"
        >
        </BackgroundImage>*/}

        {/* <div className="site-breadcrumb">
            <div className="container">		
            </div>
        </div> */}

        <ServicesComp intl={intl} page="service"></ServicesComp>

        {/* <div className="site-breadcrumb">
            <div className="container">		
            </div>
        </div> */}

    </section>
</Layout>
)

export default injectIntl(Services)
export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
            }
        }
    },
}
`